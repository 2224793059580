const ENV = 'staging'; // local | staging | production
var Data = {};
if (ENV == 'local' || ENV == 'staging') {
    Data = {        
        SITEURL                         : ENV   == 'local' ?  "http://localhost:4200" : 'https://stagingv4.slomins.com',
        ALARAM_SITEURL                  : "https://alarmdev.slomins.com",
        OIL_SITEURL                     : "https://oildev.slomins.com",
        AC_SITEURL                      : "https://acdev.slomins.com",
        MYA_SITEURL                     : "https://mya.slomins.com/Account/Index",
        TRUSTPILOT_API_URL              : "https://api.trustpilot.com/v1/business-units/515214ce000064000524f576/reviews",
        TRUSTPILOT_API_KEY              : "357NegaWF2pGs5a4Apk7CjajAFA7qyHV",
        TRUSTPILOT_PER_PAGE             : 10,
        TRUSTPILOT_TAG_VALUE            : "", 
        EMAIL_SUBSCRIBE_URL             : "https://slomins.us9.list-manage.com/subscribe/post-json",
        SUBSCRIBE_U_KEY                 : "eb5c996931f5a0a5c06cd63d7",
        SUBSCRIBE_ID                    : "ecff613563",
        SUBSCRIBE_UEMAIL                : "",
        STATUSPAGE_URL                  : "https://slomins.statuspage.io/",
        GETAPPOINT_DATE                 : "https://svcs.slomins.com/Prod/Corp/1.1c/api/cross/GetAppointDate",       
        POSTLEAD_API_URL                : "https://qa2.slomins.com/QA/CloudData/2.1/CloudData.svc/PostLead",
        FEEDBACK_API_URL                : "https://qa2.slomins.com/QA/CloudData/2.1/CloudData.svc/PostLead",
        TRUSTPILOT_REVIEW_API_URL       : "https://www.trustpilot.com/review/slomins.com",
        SYSTEM_STATUS_PAGE_URL          : "https://slominsqa.statuspage.io",
        GOOGLE_ANALYTICS                : true,
        GOOGLE_ANALYTICS_KEY            : "G-DWCX5MLNNC",
        GOOGLE_TAGMANAGER_KEY           : "GTM-54R8D9KZ",
        SMARTLOOK_TRACKING              : false,
        SMARTLOOK_TRACKING_KEY          : "f8b59092b7f6d949db9322fd4ebe4305b17f75bb",
        INCIDENT_REPORT_API_URL         : "https://mhdfbycb9zc0.statuspage.io/api/v2/summary.json",
        IP_ADDRESS                      : 'https://authdevapi.slomins.com/api/get-ipaddress',
        MARKETING_API_BASEPATH          : ENV == 'local' ? "http://marketingapi.local/api" : 'https://marketingapidev.slomins.com/api', 
        AWS_BUCKET_IMAGE_URL            : "https://marketing-site-blog-images-qa.s3.us-east-1.amazonaws.com/"
    }; 
} else if (ENV == 'preproduction') { 
    Data = { 
        GOOGLE_ANALYTICS                : true,
        GOOGLE_ANALYTICS_KEY            : "UA-193271-3",
        GOOGLE_TAGMANAGER_KEY           : "GTM-WNWLBBZ",
        SMARTLOOK_TRACKING              : true,
        SMARTLOOK_TRACKING_KEY          : "f8b59092b7f6d949db9322fd4ebe4305b17f75bb",
        SITEURL                         : "https://prewww.slomins.com",
        ALARAM_SITEURL                  : "https://prealarm.slomins.com",
        OIL_SITEURL                     : "https://preoil.slomins.com",
        AC_SITEURL                      : "https://preac.slomins.com",
        MYA_SITEURL                     : "https://mya.slomins.com/Account/Index",
        TRUSTPILOT_API_URL              : "https://api.trustpilot.com/v1/business-units/515214ce000064000524f576/reviews",
        TRUSTPILOT_API_KEY              : "357NegaWF2pGs5a4Apk7CjajAFA7qyHV",
        TRUSTPILOT_PER_PAGE             : 10,
        TRUSTPILOT_TAG_VALUE            : "", 
        EMAIL_SUBSCRIBE_URL             : "https://slomins.us9.list-manage.com/subscribe/post-json",
        SUBSCRIBE_U_KEY                 : "eb5c996931f5a0a5c06cd63d7",
        SUBSCRIBE_ID                    : "ecff613563",
        SUBSCRIBE_UEMAIL                : "",
        STATUSPAGE_URL                  : "https://slomins.statuspage.io/",
        GETAPPOINT_DATE                 : "https://svcs.slomins.com/Prod/Corp/1.1c/api/cross/GetAppointDate",  
        POSTLEAD_API_URL                : "https://svcs.slomins.com/Prod/CloudData/2.3/CloudData.svc/PostLead",
        FEEDBACK_API_URL                : "https://svcs.slomins.com/Prod/CloudData/2.3/CloudData.svc/PostLead",
        TRUSTPILOT_REVIEW_API_URL       : "https://www.trustpilot.com/review/slomins.com",
        SYSTEM_STATUS_PAGE_URL          : "https://slomins.statuspage.io",
        INCIDENT_REPORT_API_URL         : "https://dmdxmz52tg8k.statuspage.io/api/v2/summary.json",
        IP_ADDRESS                      : "https://authapi.slomins.com/api/get-ipaddress",
        MARKETING_API_BASEPATH          : 'https://marketingapidev.slomins.com/api',
        AWS_BUCKET_IMAGE_URL            : "https://marketing-site-blog-images-qa.s3.us-east-1.amazonaws.com/"
    };
} else { 
    Data = { 
        GOOGLE_ANALYTICS                : true,
        GOOGLE_ANALYTICS_KEY            : "UA-193271-3",
        GOOGLE_TAGMANAGER_KEY           : "GTM-WNWLBBZ",
        SMARTLOOK_TRACKING              : true,
        SMARTLOOK_TRACKING_KEY          : "f8b59092b7f6d949db9322fd4ebe4305b17f75bb",
        SITEURL                         : "https://www.slomins.com",
        ALARAM_SITEURL                  : "https://alarm.slomins.com",
        OIL_SITEURL                     : "https://oil.slomins.com",
        AC_SITEURL                      : "https://ac.slomins.com",
        MYA_SITEURL                     : "https://mya.slomins.com/Account/Index",
        TRUSTPILOT_API_URL              : "https://api.trustpilot.com/v1/business-units/515214ce000064000524f576/reviews",
        TRUSTPILOT_API_KEY              : "357NegaWF2pGs5a4Apk7CjajAFA7qyHV",
        TRUSTPILOT_PER_PAGE             : 10,
        TRUSTPILOT_TAG_VALUE            : "", 
        EMAIL_SUBSCRIBE_URL             : "https://slomins.us9.list-manage.com/subscribe/post-json",
        SUBSCRIBE_U_KEY                 : "eb5c996931f5a0a5c06cd63d7",
        SUBSCRIBE_ID                    : "ecff613563",
        SUBSCRIBE_UEMAIL                : "",
        STATUSPAGE_URL                  : "https://slomins.statuspage.io/",
        GETAPPOINT_DATE                 : "https://svcs.slomins.com/Prod/Corp/1.1c/api/cross/GetAppointDate",  
        POSTLEAD_API_URL                : "https://svcs.slomins.com/Prod/CloudData/2.3/CloudData.svc/PostLead",
        FEEDBACK_API_URL                : "https://svcs.slomins.com/Prod/CloudData/2.3/CloudData.svc/PostLead",
        TRUSTPILOT_REVIEW_API_URL       : "https://www.trustpilot.com/review/slomins.com",
        SYSTEM_STATUS_PAGE_URL          : "https://slomins.statuspage.io",
        INCIDENT_REPORT_API_URL         : "https://dmdxmz52tg8k.statuspage.io/api/v2/summary.json",
        IP_ADDRESS                      : "https://authapi.slomins.com/api/get-ipaddress",
        MARKETING_API_BASEPATH          : 'https://marketingapidev.slomins.com/api',
        AWS_BUCKET_IMAGE_URL            : "https://marketing-site-blog-images-qa.s3.us-east-1.amazonaws.com/"   
    };
}

export const constant: any = Data; 