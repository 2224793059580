import React, { useState, useEffect } from "react";
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import { useParams } from 'react-router-dom';
import SocialMedia from '../../components/social-media/SocialMedia';
import { constant } from '@app/utils/constant';
import { toast } from 'react-toastify';
import BlogYouMayAlsoLikeSection from '../../components/blog/BlogYouMayAlsoLikeSection';
import PageTitleComponent from '../../components/PageTitleComponent';
import PageNotFound from '@app/pages/Page404'; 
import Loader from '../../components/loading/Loader';
import moment from 'moment';
import { getCategoryList, getBlogBySlugList } from "@app/services/blog";
const BlogDetails = () => {
    const [isLoading, setLoading] = useState(false);
    const [blogDataObj, setBlogDataObj] = useState("");
    const [categoryData, setCategoryData] = useState("");
    const [checkSlugExitOrNot, setCheckSlugExitOrNot] = useState(false);
    const { slug } = useParams();
    const ImageUrl = constant.MARKETING_API_IMAGEPATH;
    setTimeout(() => {
        window.scrollTo(1, 0);
    }, 100);

    useEffect(() => {
        fetchBlogBySlug(slug);
        getCategoryData();
    }, [slug]);

    const getCategoryData = () => {
        getCategoryList().then(function (res) {
            const resp = res.data;
            let cateData: any = [];
            if (resp.Acknowledge == 1) {
                resp.data.map((val: any, i: any) => {
                    cateData.push({
                        value: val.id,
                        label: val.name
                    });
                });
                setCategoryData(cateData);
            } else {
                toast.error('Validation error found');
            }
        }).catch(err => {
            toast.error('Internal Server Error!');
        });
    };
    const findLabelsByValues = (valuesString) => {
        if (!valuesString) return ""; // Return empty string if no values provided 
        return valuesString
            .split(",")
            .map(value => {
                const option = (categoryData) ? categoryData.find(option => option.value === parseInt(value, 10)) : "";
                return option ? option.label : null; // Return the label if found
            })
            .filter(Boolean)
            .join(", ");
    };

    const fetchBlogBySlug = (slug: any = "") => {
        setLoading(true);
        getBlogBySlugList(slug).then(function (res) {
            setLoading(false);
            const resp = res.data;
            let BlogData: any = [];
            if (resp.Acknowledge == 1) {
                BlogData = {
                    title: resp.data.title,
                    frontImageUrl: ImageUrl + '/small/' + resp.data.image,
                    category: resp.data.category,
                    slug: resp.data.slug,
                    date: moment(resp.data.created_at).format("MMMM DD, YYYY"),
                    content: resp.data.content,
                    blogBannerImage: ImageUrl + '/large/' + resp.data.image
                };
                setBlogDataObj(BlogData);
            } else if(resp.Acknowledge == 0) {
                setCheckSlugExitOrNot(true);
                toast.error('Validation error found');
            }
        }).catch(err => {
            setLoading(false);
            toast.error('Internal Server Error!');
        });
    };

    const truncateText = (text, limit) => {
        if (text.length <= limit) return text;
        return text.slice(0, text.lastIndexOf(" ", limit)) + "";
    };

    const metaTags = [
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:type', content: 'article' },
        { property: 'og:title', content: blogDataObj.title !== undefined ? blogDataObj.title : "" },
        { property: 'og:description', content: blogDataObj.content !== undefined ? truncateText(blogDataObj.content, 150) : "" },
        { property: 'og:url', content: constant.SITEURL + '/' + slug },
        { property: 'og:site_name', content: 'Slomins' },
        { property: 'og:image', content: blogDataObj.frontImageUrl !== undefined ? blogDataObj.frontImageUrl : "" },
        { property: 'og:image:width', content: '375' },
        { property: 'og:image:height', content: '375' },
        { property: 'og:image:type', content: 'image/jpeg' },

        { property: 'article:publisher', content: 'https://www.facebook.com/slomins/' },
        { property: 'article:published_time', content: blogDataObj.date !== undefined ? moment(blogDataObj.date).format("MMMM DD, YYYY") : "" },
        { property: 'article:modified_time', content: blogDataObj.date !== undefined ? moment(blogDataObj.date).format("MMMM DD, YYYY") : "" },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:creator', content: '@Slomins' },
        { property: 'twitter:site', content: '@Slomins' },
        { property: 'twitter:label1', content: 'Written by' },
        { property: 'twitter:data1', content: 'Paolo Yabut' },
        { property: 'twitter:label2', content: 'Est. reading time' },
        { property: 'twitter:data2', content: '3 minutes' },
    ];
    const handlePrint = () => {
        window.print();
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
        
            <Loader isLoading={isLoading} />
             {blogDataObj.title !== undefined ?  
            <>
                <PageTitleComponent title={blogDataObj.title !== undefined ? blogDataObj.title + ' - Slomins' : ""} />
                <main className="blog_details_page">
                    <section className="hero_blk" style={{ backgroundImage: `url(${blogDataObj.blogBannerImage})` }}>
                        <div className="hero_inr container-xxl">
                            <div className="hero_content text-center">
                                <h1>{blogDataObj.title !== undefined ? blogDataObj.title : ""}</h1>
                            </div>
                        </div>
                    </section>
                    <SocialMedia metaTags={metaTags} />
                    <section className="blog-content-blk">
                        <div className="blog-content-inr container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="back_btn_blk">
                                        <a href="/blog">Blog Home</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center blog-top-blk">
                                <div className="col-sm-6 col-12">
                                    <div className="blog-top-content blog_date_cat_blk">
                                        <h2>{blogDataObj.category !== undefined ? findLabelsByValues(blogDataObj.category) : ""}</h2>
                                        <div className="blog-date">{blogDataObj.date !== undefined ? blogDataObj.date : ""}</div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                    <div className="social-icons">
                                        <ul>
                                            <li>
                                                <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(constant.SITEURL + '/' + slug)}`} title="Share on Facebook" target="_blank">
                                                    <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.4058 0.00416134L7.81228 0C4.89851 0 3.01551 1.9319 3.01551 4.92203V7.19141H0.407811C0.182475 7.19141 0 7.37409 0 7.59943V10.8875C0 11.1128 0.182683 11.2953 0.407811 11.2953H3.01551V19.5922C3.01551 19.8175 3.19798 20 3.42332 20H6.82563C7.05096 20 7.23344 19.8173 7.23344 19.5922V11.2953H10.2824C10.5078 11.2953 10.6903 11.1128 10.6903 10.8875L10.6915 7.59943C10.6915 7.49123 10.6484 7.38762 10.5721 7.31105C10.4957 7.23448 10.3917 7.19141 10.2835 7.19141H7.23344V5.26763C7.23344 4.34298 7.45378 3.87358 8.65828 3.87358L10.4054 3.87295C10.6305 3.87295 10.813 3.69027 10.813 3.46514V0.411972C10.813 0.187052 10.6308 0.00457747 10.4058 0.00416134Z" fill="#EE3B33"></path></svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a href={`https://twitter.com/share?text=${encodeURIComponent(slug)}&url=${encodeURIComponent(constant.SITEURL + '/' + slug)}`} title="Post on Twitter" target="_blank">
                                                    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.20747 18C7.25612 18 6.27691 17.923 5.29183 17.7686C3.55681 17.4965 1.80048 16.5892 1.30349 16.3175L0 15.6051L1.41124 15.1411C2.95391 14.6341 3.89233 14.3195 5.05399 13.8268C3.89082 13.2637 2.99436 12.2507 2.56283 10.9413L2.23453 9.94492L2.50375 9.98602C2.24846 9.7288 2.04687 9.46771 1.89061 9.23028C1.33487 8.38645 1.04064 7.35536 1.10325 6.47176L1.16501 5.60209L1.68584 5.80378C1.46613 5.38899 1.30735 4.94602 1.21403 4.48173C0.986595 3.34946 1.17693 2.14655 1.75012 1.09466L2.20381 0.262068L2.81057 0.990627C4.72955 3.2951 7.16012 4.66211 10.0449 5.0623C9.92705 4.25035 10.0153 3.46675 10.3076 2.76437C10.6478 1.94655 11.253 1.25306 12.0573 0.758736C12.9506 0.209885 14.0339 -0.0560678 15.1076 0.00987498C16.2468 0.0798448 17.281 0.506543 18.1025 1.24467C18.5036 1.14047 18.7992 1.02922 19.1987 0.878876C19.439 0.788436 19.7116 0.685746 20.0527 0.568291L21.3095 0.135384L20.4899 2.47543C20.5439 2.4709 20.5997 2.46704 20.6579 2.46452L22 2.40378L21.2068 3.48756C21.1613 3.54964 21.1497 3.56726 21.1336 3.59159C21.0696 3.6879 20.9901 3.80771 19.9013 5.26113C19.6287 5.62508 19.4926 6.09909 19.5179 6.5961C19.6144 8.48478 19.3826 10.1936 18.8286 11.6749C18.3045 13.0763 17.4925 14.2787 16.4153 15.2487C15.0823 16.4488 13.3825 17.2704 11.363 17.6906C10.3723 17.8966 9.30854 18 8.20747 18Z" fill="#EE3B33"></path>
                                                    </svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a href={`mailto:?subject=${encodeURIComponent(blogDataObj.title !== undefined ? blogDataObj.title : "")}`} title="Share by Email">
                                                    <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.1983 11.2064L8.42712 8.7793L0.503906 15.5754C0.791907 15.8428 1.18071 16.0093 1.60951 16.0093H20.7871C21.2143 16.0093 21.6015 15.8428 21.8879 15.5754L13.9695 8.7793L11.1983 11.2064Z" fill="#EE3B33"></path><path d="M21.8934 0.433864C21.6054 0.1649 21.2182 0 20.7878 0H1.61021C1.18301 0 0.795813 0.166501 0.507812 0.437065L11.199 9.60583L21.8934 0.433864Z" fill="#EE3B33"></path><path d="M0 1.40527V14.7062L7.73279 8.13097L0 1.40527Z" fill="#EE3B33"></path><path d="M14.668 8.13087L22.4008 14.7061V1.40039L14.668 8.13087Z" fill="#EE3B33"></path>
                                                    </svg>
                                                </a>
                                            </li>
                                            <li>
                                                <svg onClick={handlePrint} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.1411 1.42944C17.1411 0.640391 16.5011 0 15.7125 0H4.28403C3.49547 0 2.85547 0.640391 2.85547 1.42944V2.85889H17.1411V1.42944Z" fill="#EE3B33"></path><path fillRule="evenodd" clipRule="evenodd" d="M18.5714 4.28809H1.42857C0.64 4.28809 0 4.92847 0 5.71752V14.2941C0 15.0832 0.64 15.7235 1.42857 15.7235H2.85714V18.5824C2.85714 19.3715 3.49714 20.0118 4.28571 20.0118H15.7143C16.5029 20.0118 17.1429 19.3715 17.1429 18.5824V15.7235H18.5714C19.36 15.7235 20 15.0832 20 14.2941V5.71752C20 4.92847 19.36 4.28809 18.5714 4.28809ZM15.7142 18.5824H4.28577V11.4353H15.7142V18.5824ZM14.2858 8.57642H17.1428V7.14685H14.2858V8.57642Z" fill="#EE3B33"></path><rect x="5.71484" y="15.7236" width="4.28577" height="1.42932" fill="#EE3B33"></rect><rect x="5.71484" y="12.8652" width="8.57153" height="1.42932" fill="#EE3B33"></rect></svg>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center blog-hero-blk">
                                <div className="col-12 col-md-12">
                                    <div className="blog-bottom-content">
                                        <div dangerouslySetInnerHTML={{ __html: blogDataObj.content !== undefined ? blogDataObj.content : "" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center blog-top-blk soc_links_bottom">
                                <div className="col-sm-6 col-12">
                                    <div className="blog-top-content blog_date_cat_blk">
                                        <h2>{blogDataObj.category !== undefined ? findLabelsByValues(blogDataObj.category) : ""} </h2>
                                        <div className="blog-date">{blogDataObj.date !== undefined ? blogDataObj.date : ""}</div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                    <div className="social-icons">
                                        <ul>
                                            <li>
                                                <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(constant.SITEURL + '/' + slug)}`} title="Share on Facebook" target="_blank">
                                                    <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.4058 0.00416134L7.81228 0C4.89851 0 3.01551 1.9319 3.01551 4.92203V7.19141H0.407811C0.182475 7.19141 0 7.37409 0 7.59943V10.8875C0 11.1128 0.182683 11.2953 0.407811 11.2953H3.01551V19.5922C3.01551 19.8175 3.19798 20 3.42332 20H6.82563C7.05096 20 7.23344 19.8173 7.23344 19.5922V11.2953H10.2824C10.5078 11.2953 10.6903 11.1128 10.6903 10.8875L10.6915 7.59943C10.6915 7.49123 10.6484 7.38762 10.5721 7.31105C10.4957 7.23448 10.3917 7.19141 10.2835 7.19141H7.23344V5.26763C7.23344 4.34298 7.45378 3.87358 8.65828 3.87358L10.4054 3.87295C10.6305 3.87295 10.813 3.69027 10.813 3.46514V0.411972C10.813 0.187052 10.6308 0.00457747 10.4058 0.00416134Z" fill="#EE3B33"></path></svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a href={`https://twitter.com/share?text=${encodeURIComponent(slug)}&amp;url=${encodeURIComponent(constant.SITEURL + '/' + slug)}`} title="Post on Twitter" target="_blank">
                                                    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.20747 18C7.25612 18 6.27691 17.923 5.29183 17.7686C3.55681 17.4965 1.80048 16.5892 1.30349 16.3175L0 15.6051L1.41124 15.1411C2.95391 14.6341 3.89233 14.3195 5.05399 13.8268C3.89082 13.2637 2.99436 12.2507 2.56283 10.9413L2.23453 9.94492L2.50375 9.98602C2.24846 9.7288 2.04687 9.46771 1.89061 9.23028C1.33487 8.38645 1.04064 7.35536 1.10325 6.47176L1.16501 5.60209L1.68584 5.80378C1.46613 5.38899 1.30735 4.94602 1.21403 4.48173C0.986595 3.34946 1.17693 2.14655 1.75012 1.09466L2.20381 0.262068L2.81057 0.990627C4.72955 3.2951 7.16012 4.66211 10.0449 5.0623C9.92705 4.25035 10.0153 3.46675 10.3076 2.76437C10.6478 1.94655 11.253 1.25306 12.0573 0.758736C12.9506 0.209885 14.0339 -0.0560678 15.1076 0.00987498C16.2468 0.0798448 17.281 0.506543 18.1025 1.24467C18.5036 1.14047 18.7992 1.02922 19.1987 0.878876C19.439 0.788436 19.7116 0.685746 20.0527 0.568291L21.3095 0.135384L20.4899 2.47543C20.5439 2.4709 20.5997 2.46704 20.6579 2.46452L22 2.40378L21.2068 3.48756C21.1613 3.54964 21.1497 3.56726 21.1336 3.59159C21.0696 3.6879 20.9901 3.80771 19.9013 5.26113C19.6287 5.62508 19.4926 6.09909 19.5179 6.5961C19.6144 8.48478 19.3826 10.1936 18.8286 11.6749C18.3045 13.0763 17.4925 14.2787 16.4153 15.2487C15.0823 16.4488 13.3825 17.2704 11.363 17.6906C10.3723 17.8966 9.30854 18 8.20747 18Z" fill="#EE3B33"></path>
                                                    </svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a href={`mailto:?subject=${encodeURIComponent(blogDataObj.title !== undefined ? blogDataObj.title : "")}`} title="Share by Email">
                                                    <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.1983 11.2064L8.42712 8.7793L0.503906 15.5754C0.791907 15.8428 1.18071 16.0093 1.60951 16.0093H20.7871C21.2143 16.0093 21.6015 15.8428 21.8879 15.5754L13.9695 8.7793L11.1983 11.2064Z" fill="#EE3B33"></path><path d="M21.8934 0.433864C21.6054 0.1649 21.2182 0 20.7878 0H1.61021C1.18301 0 0.795813 0.166501 0.507812 0.437065L11.199 9.60583L21.8934 0.433864Z" fill="#EE3B33"></path><path d="M0 1.40527V14.7062L7.73279 8.13097L0 1.40527Z" fill="#EE3B33"></path><path d="M14.668 8.13087L22.4008 14.7061V1.40039L14.668 8.13087Z" fill="#EE3B33"></path>
                                                    </svg>
                                                </a>
                                            </li>
                                            <li>
                                                <svg onClick={handlePrint} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.1411 1.42944C17.1411 0.640391 16.5011 0 15.7125 0H4.28403C3.49547 0 2.85547 0.640391 2.85547 1.42944V2.85889H17.1411V1.42944Z" fill="#EE3B33"></path><path fillRule="evenodd" clipRule="evenodd" d="M18.5714 4.28809H1.42857C0.64 4.28809 0 4.92847 0 5.71752V14.2941C0 15.0832 0.64 15.7235 1.42857 15.7235H2.85714V18.5824C2.85714 19.3715 3.49714 20.0118 4.28571 20.0118H15.7143C16.5029 20.0118 17.1429 19.3715 17.1429 18.5824V15.7235H18.5714C19.36 15.7235 20 15.0832 20 14.2941V5.71752C20 4.92847 19.36 4.28809 18.5714 4.28809ZM15.7142 18.5824H4.28577V11.4353H15.7142V18.5824ZM14.2858 8.57642H17.1428V7.14685H14.2858V8.57642Z" fill="#EE3B33"></path><rect x="5.71484" y="15.7236" width="4.28577" height="1.42932" fill="#EE3B33"></rect><rect x="5.71484" y="12.8652" width="8.57153" height="1.42932" fill="#EE3B33"></rect></svg>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                    <BlogYouMayAlsoLikeSection categoryIds={blogDataObj.category} slug={slug} />
                    <SubscribeCompoents />
                </main>
            </>
             :  checkSlugExitOrNot ? <PageNotFound /> : null
            }  
        </>
    );
};

export default BlogDetails;
