import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom'
import Loader from '../../components/loading/Loader';
import { toast } from 'react-toastify';
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import BlogSection from '../../components/blog/BlogSection';
import PaginatedItems from "../../components/PaginatedItems";
import PageTitleComponent from '../../components/PageTitleComponent';
import { getCategoryList, getBlogDataList } from "@app/services/blog";
const Blogs = () => {
    const [isLoading, setLoading] = useState(false);
    const [categoryName, setCategoryName] = useState("all"); 
    const [categoryData, setCategoryData] = useState("");
    const [blogData, setBlogData] = useState([]);
    const [totalLen, setTotalLen] = useState("");
    const params = useParams();
    const pageId = params.id ? params.id : 1;
    let offset = pageId;
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('all');
    const handleClick = (value: React.SetStateAction<string>, cateNumber: any) => {
        setActiveTab(value); 
        fetchBlogData(cateNumber, offset);
        setCategoryName(value);
        if (value == 'all') {
            navigate('/blog');
        } else {
            navigate('/category/' + value);
        }
    };

    useEffect(() => {
        getCategoryData();
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
        fetchBlogData(1, offset);
    }, [offset]);

    var newStrCategory = categoryName.replace(/-/g, " ");
    const fetchBlogData = (filterData: any = "", offset: any) => {
        setLoading(true);
        getBlogDataList(filterData, offset).then(function (res) {
             setLoading(false);
            const resp = res.data;
            if (resp.Acknowledge == 1) {
                setTotalLen(resp.total_records);
                setBlogData(resp.data);
            } else {
                toast.error('Validation error found');
            }
        }).catch(err => {
             setLoading(false);
            toast.error('Internal Server Error!');
        });
    };

    const getCategoryData = () => {
        getCategoryList().then(function (res) {
            const resp = res.data;
            let cateData: any = [];
            if (resp.Acknowledge == 1) {
                resp.data.map((val: any, i: any) => {
                    cateData.push({
                        value: val.id,
                        label: val.name
                    });
                });
                setCategoryData(cateData);
            } else {
                toast.error('Validation error found');
            }
        }).catch(err => {
            toast.error('Internal Server Error!');
        });
    };
    return (
        <>
         <Loader isLoading={isLoading} />
            <PageTitleComponent title={categoryName == 'all' ? "Blog - Slomins" : "Helpful resources about " + categoryName.charAt(0).toUpperCase() + categoryName.slice(1) + "- Slomins"} />
            <main className="blog_page">
                <section className="hero_blk">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content text-center">
                            <h1>{newStrCategory == 'all' ? "Blog" : newStrCategory.charAt(0).toUpperCase() + newStrCategory.slice(1)}</h1>
                            <p>{categoryName == 'all' ? "Helpful resources for home owners and business owners." : "Helpful resources about " + newStrCategory.charAt(0).toUpperCase() + newStrCategory.slice(1) + ""}</p>
                        </div>
                    </div>
                </section> 
                <section className="blog_tab-blk container"> 
                    <div className="blog-tab-inr-sec"> 
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            {
                                categoryData && categoryData.map((item: any, index: any) => {
                                    return (
                                        <li className="nav-item" role="presentation" key={index}>
                                            <button className={`nav-link ${activeTab === item.label.toLowerCase() ? "active" : ""}`} onClick={(e) => handleClick(item.label.toLowerCase().replace(/\s+/g, "-"), item.value)} id={`v-pills-${item.value}-tab`} data-bs-toggle="pill" data-bs-target={`#v-pills-${item.value}`} type="button" role="tab" aria-controls={`v-pills-${item.value}`} aria-selected="true"><span className="p-2">{item.label}</span></button>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    
                    <div className="tab-content" id="v-pills-tabContent">
                        <BlogSection categoryName={categoryName} defaultclassName={'active'} blogData={blogData} categoryData={categoryData} />
                    </div>
                    <PaginatedItems itemsPerPage={10} totalItem={totalLen} />
                    
                </section>
                <SubscribeCompoents />
                
            </main>
        </>
    );
};

export default Blogs;
