import axios from 'axios';
import BlogJSONData from '../components/blog/BlogData';
import { constant } from '@app/utils/constant';
import Cookies from 'js-cookie';
export const getBlogList = async (category: any, offset: any) => {  // remove getBlogList Function after deploy on QA  
  try {
    let lem: any = 0;
    let BlogArr: any = [];
    BlogJSONData.forEach((item, index) => {
      if (item.category === category) {  
        BlogArr[lem] = {
          title: item.title,
          subtitle: item.subtitle,
          frontImageUrl: item.frontImageUrl,
          category: item.category,
          description: item.description,
          slug: item.slug,
          date: item.date,
          content: item.content,
        };
        lem++;
      }
    });
    return {
      'total': lem,
      'data': BlogArr.slice((offset - 1) * 10, offset * 10),
    };
  } catch (err) {
    return { 'data': err };
  }
};

export const getBlogDataList = async (category: any, offset: any) => {   
//  let token = Cookies.get(constant.Cookie_Name); 
  try {
    const response = await axios.get(constant.MARKETING_API_BASEPATH + '/front-blogs?category='+category+'&page='+offset+'&per_page=10' + "", { headers: { 'Content-Type': 'application/json'} });
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getBlogBySlugList = async (slug: any) => {   
  //  let token = Cookies.get(constant.Cookie_Name); 
    try {
      const response = await axios.get(constant.MARKETING_API_BASEPATH + '/front-blogs/get-blog-by-slug?slug='+slug, { headers: { 'Content-Type': 'application/json'} });
      return response;
    } catch (err) {
      console.log(err);
    }
  };

export const getCategoryList = async () => {    
  let restype = 'json';
  // let token = Cookies.get(constant.Cookie_Name); 
  try { 
    const response = await axios.get(constant.MARKETING_API_BASEPATH + '/front-blogs/get-category-list', { headers: { 'Content-Type': 'application/json'} });
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getMayAlsoLikeList = async (categoryID: any) => {    
  let restype = 'json';
  // let token = Cookies.get(constant.Cookie_Name); 
  try { 
    const response = await axios.get(constant.MARKETING_API_BASEPATH + '/front-blogs/get-may-also-like-list?category_id='+categoryID, { headers: { 'Content-Type': 'application/json'} });
    return response;
  } catch (err) {
    console.log(err);
  }
};









