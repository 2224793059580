import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { constant } from '@app/utils/constant';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getMayAlsoLikeList, getCategoryList } from "@app/services/blog";
const BlogYouMayAlsoLikeSection = (props: any) => {
    const [blogArr, setBlogArr] = React.useState("");
    const [categoryData, setCategoryData] = useState("");

    useEffect(() => {
        fetchMayAlsoLikeList(props.categoryIds);
        getCategoryData();
    }, [props.categoryIds]);
    const handleClick = () => {
        window.scrollTo(0, 0);
    }

    const fetchMayAlsoLikeList = (categoryID: any = "") => {
        getMayAlsoLikeList(categoryID).then(function (res) {
            const resp = res.data;
            if (resp.Acknowledge == 1) {
                setBlogArr(resp.data);
            } else {
                toast.error('Validation error found');
            }
        }).catch(err => {
            toast.error('Internal Server Error!');
        });
    };
    const getCategoryData = () => {
        getCategoryList().then(function (res) {
            const resp = res.data;
            let cateData: any = [];
            if (resp.Acknowledge == 1) {
                resp.data.map((val: any, i: any) => {
                    cateData.push({
                        value: val.id,
                        label: val.name
                    });
                });
                setCategoryData(cateData);
            } else {
                toast.error('Validation error found');
            }
        }).catch(err => {
            toast.error('Internal Server Error!');
        });
    };

    const findLabelsByValues = (valuesString) => {
        if (!valuesString) return ""; // Return empty string if no values provided 
        return valuesString
            .split(",")
            .map(value => {
                const option = (categoryData) ? categoryData.find(option => option.value === parseInt(value, 10)) : "";
                return option ? option.label : null; // Return the label if found
            })
            .filter(Boolean)
            .join(", ");
    };

    return (
        <>
            {props.slug === 'blog-why-commercial-security-systems-are-essential-for-protecting-your-business' ? "" :
                <section className="related-blog">
                    <div className="related-blog-inr container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-12">
                                <div className="related-blog-heading">You may <span>also like</span></div>
                            </div>
                            {
                                blogArr.length > 0 ? blogArr.slice(0, 3).map((row, podidx) => (
                                    <div key={podidx} className="col-md-6 col-lg-4 col-12">
                                        {
                                            <div className="card">
                                                <div className="image-wrapper">
                                                    <Link to={`/` + row.slug} onClick={handleClick}>
                                                        <img src={constant.AWS_BUCKET_IMAGE_URL + 'large/' + row.image} alt={row.title} />
                                                    </Link>
                                                </div>
                                                <div className="card-content">
                                                    <div className="note">{findLabelsByValues(row.category)} | <span className="date">{moment(row.created_at).format("MMMM DD, YYYY")}</span></div>
                                                    <h4>{row.title}</h4>
                                                    <Link to={`/` + row.slug} className="button white_btn prd_learn_more" onClick={handleClick}>
                                                        Learn More
                                                    </Link>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )) : ""

                            }

                        </div>
                    </div>
                </section>
            }
        </>
    );
};

export default BlogYouMayAlsoLikeSection;
